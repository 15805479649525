/*
 * @Date: 2021-03-04 15:31:31
 * @LastEditors: ZHT
 * @LastEditTime: 2021-03-04 16:07:09
 */
import React from 'react'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { DeviceInfo as DeviceInfoContext } from '@/context/device-info'
import { getDeviceInfo } from '@/bridges'
import i18n from '@/lang/i18n'
import routes from './routes'
import './App.css'
import { getParamsFromSearchStr } from './utils'

const queryClient = new QueryClient()
const App: React.FC = () => {
  let config: DeviceInfo = {
    gameCode: '',
    env: '',
  }
  const locationParams = getParamsFromSearchStr()
  if (locationParams.from && locationParams.from === 'direct') {
    config.gameCode = (locationParams.gameCode as string) || ''
    config.env = (locationParams.env as string) || ''
  } else {
    config = getDeviceInfo()
  }

  localStorage.setItem('JOY_SDK_DEVICE_INFO', JSON.stringify(config))
  i18n.changeLanguage((config.lang || 'en').replace('-', ''))
  return (
    <QueryClientProvider client={queryClient}>
      <DeviceInfoContext.Provider value={config}>
        <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
      </DeviceInfoContext.Provider>
    </QueryClientProvider>
  )
}

export default App
