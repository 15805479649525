import md5 from 'js-md5'
import { Toast } from 'antd-mobile'

interface Response {
  code: number
  current_time: number
  message: string
  data: JsonObject
}

const request = (url: string, config: RequestInit = {}): Promise<Response> => {
  const timestamp = Date.parse(`${new Date()}`) / 1000
  const key = '82B1D5DFB4318A5C1B333E559227F7DD'
  const sign = md5(`${timestamp}${key}`).toUpperCase()

  const deviceInfo: DeviceInfo = localStorage.getItem('JOY_SDK_DEVICE_INFO')
    ? JSON.parse(localStorage.getItem('JOY_SDK_DEVICE_INFO') || '')
    : {}

  const realConfig: RequestInit = {
    headers: {
      Time: `${timestamp}`,
      'content-type': 'application/json',
      PackageCode: deviceInfo.packageCode || '',
      DeviceId: deviceInfo.deviceId || '',
      LangCode: deviceInfo.lang || '',
      GameCode: deviceInfo.gameCode,
      Sign: sign,
      Token: deviceInfo.token || '',
      Env: deviceInfo.env,
    },
    ...config,
  }

  return fetch(url, realConfig)
    .then((res: any) => res.json())
    .then((resJson: any) => {
      if (resJson.code !== 200) {
        throw resJson
      } else {
        return resJson
      }
    })
    .catch((err) => {
      Toast.fail(err.message)
      throw err
    })
}

// GET请求
export const get = (url: string, data: JsonObject = {}): Promise<Response> => {
  const keysArray = Object.keys(data)
  if (keysArray.length > 0) {
    url += '?'
    url += keysArray.map((key) => `${key}=${data[key]}`).join('&')
  }
  return request(url, { method: 'GET' })
}

// POST请求
export const post = (url: string, data: JsonObject = {}): Promise<Response> => {
  return request(url, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}
