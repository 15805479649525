import React, { useCallback, useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { get, post } from '@/utils/fetch'
import { useTranslation } from 'react-i18next'
import BottomBar from '@/components/bottom-bar'
import HTMLContainer from '@/components/html-container'
import Page from '@/components/page'
import { Button, Toast, Checkbox, Text } from 'antd-mobile'
import { getRoleInfo, closeWebview, logOffSuccess } from '@/bridges'
import { useHistory, useLocation } from 'react-router'
import { DeviceInfo } from '@/context/device-info'
import { ProtocolReturnData, RoleInfo } from './type'
import { goToNext } from './utils'

interface Props {
  name: string
  idNumber: string
}

const Result: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation()
  const roleInfo: RoleInfo = getRoleInfo()
  const deviceInfo = useContext(DeviceInfo)
  const [checked, setChecked] = useState(false)

  const { data } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'confirmation',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_CONFIRMATION',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  const confirmMutation = useMutation(
    async () =>
      post('/sdk/logoff', {
        name: (state as Props).name || '',
        id_num: (state as Props).idNumber || '',
        extra: roleInfo.extra || '',
      }),
    {
      onSuccess: async (res) => {
        if (res.code === 200) {
          Toast.success(
            t('logOff.confirmation.logOffSuccess'),
            1,
            () => {
              history.replace('/log-off/result', res.data)
            },
            false
          )
          logOffSuccess({
            data: '',
          })
        }
      },
    }
  )

  const onClickConfirm = useCallback(() => {
    confirmMutation.mutate()
  }, [confirmMutation])

  const onClickCancel = useCallback(() => {
    closeWebview()
  }, [])

  const onAgreeChange = useCallback((e) => {
    setChecked(e.target.checked)
  }, [])

  return (
    <Page title={t('logOff.confirmation.title')}>
      <HTMLContainer bottomSpan={166} htmlStr={data?.data.content || ''} />
      <BottomBar>
        <Checkbox.AgreeItem defaultChecked={false} onChange={onAgreeChange}>
          {t('logOff.confirmation.confirmTip')}
        </Checkbox.AgreeItem>
        <Button
          disabled={!checked}
          type="primary"
          className="next-btn"
          onClick={onClickConfirm}
        >
          {t('logOff.confirmation.confirm')}
        </Button>
        <Button className="next-btn" onClick={onClickCancel}>
          {t('logOff.confirmation.cancel')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(Result)
