import React from 'react'
import './index.scss'

interface HTMLContainerProps {
  className?: string
  htmlStr: string
  bottomSpan?: number
}

const HTMLContainer: React.FC<HTMLContainerProps> = (
  props: HTMLContainerProps
) => {
  const { htmlStr, className, bottomSpan = 0 } = props
  const winHeight = window.innerHeight
  return (
    <div
      className={className}
      style={{ height: winHeight - bottomSpan - 40 }}
      dangerouslySetInnerHTML={{
        __html: htmlStr,
      }}
    ></div>
  )
}
HTMLContainer.defaultProps = {
  className: 'html-container',
  bottomSpan: 0,
}

export default React.memo(HTMLContainer)
