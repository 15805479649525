import React, { useState, useContext, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import { useTranslation } from 'react-i18next'
import Page from '@/components/page'
import HTMLContainer from '@/components/html-container'
import BottomBar from '@/components/bottom-bar'
import { Checkbox, Button, Text, Modal } from 'antd-mobile'
import { useHistory } from 'react-router'
import { DeviceInfo } from '@/context/device-info'
import { ProtocolReturnData } from './type'
import { goToNext, getCurrentPageConfig } from './utils'
import './index.scss'

const BriefAgreement: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  const [agreementVisible, setAgreementVisible] = useState(false)
  const deviceInfo = useContext(DeviceInfo)
  const currentPageConfig = useMemo(() => {
    return getCurrentPageConfig(
      'briefAgreement',
      deviceInfo.gameCode,
      deviceInfo.env
    )
  }, [deviceInfo.env, deviceInfo.gameCode])

  const { data } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'briefProtocol',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_BRIEF_AGREEMENT',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )
  const { data: detailData } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'protocol',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_AGREEMENT',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  const onAgreeChange = useCallback((e) => {
    setChecked(e.target.checked)
  }, [])

  const onClickNext = useCallback(() => {
    goToNext(history, 'briefAgreement', deviceInfo.gameCode, deviceInfo.env)
  }, [history, deviceInfo.gameCode, deviceInfo.env])

  const onClickAgreement = useCallback(() => {
    setAgreementVisible(true)
  }, [])

  const onClickConfirmBtn = useCallback(() => {
    setAgreementVisible(false)
  }, [])

  return (
    <Page title={t('logOff.agreement.title')}>
      <HTMLContainer bottomSpan={108} htmlStr={data?.data.content || ''} />
      <BottomBar>
        <Checkbox.AgreeItem defaultChecked={false} onChange={onAgreeChange}>
          {t('logOff.agreement.agreementRead')}
          {((currentPageConfig as any).showDetail === undefined ||
            (currentPageConfig as any).showDetail !== false) && (
            <Text className="agreement-with-quote" onClick={onClickAgreement}>
              {`${t('quoteBefore')}
            ${t('logOff.agreement.agreement')}
            ${t('quoteAfter')}`}
            </Text>
          )}
        </Checkbox.AgreeItem>
        <Button
          type="primary"
          className="next-btn"
          disabled={!checked}
          onClick={onClickNext}
        >
          {t('logOff.nextStep')}
        </Button>
      </BottomBar>
      <Modal
        transparent
        visible={agreementVisible}
        maskClosable={false}
        title={t('logOff.agreement.agreement')}
        footer={[{ text: t('confirm'), onPress: onClickConfirmBtn }]}
      >
        <div
          className="modal-html"
          dangerouslySetInnerHTML={{
            __html: detailData?.data.content || '',
          }}
        ></div>
      </Modal>
    </Page>
  )
}

export default React.memo(BriefAgreement)
