import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import VConsole from 'vconsole'
import App from './App'
import reportWebVitals from './reportWebVitals'

import '@/utils/device-judge'
import '@/utils/js-bridge'

// if (process.env.REACT_APP_ENV!.indexOf('prd') < 0) {
//   const vConsole = new VConsole()
// }

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
