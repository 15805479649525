import React from 'react'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import Page from '@/components/page'
import HtmlContainer from '@/components/html-container'
import { useTranslation } from 'react-i18next'
import { ProtocolReturnData } from '../log-off/type'
/**
 *
 * 借用协议富文本接口实现
 *
 */

const Community: React.FC = () => {
  const { t } = useTranslation()

  const { data: detailData } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'protocol',
    async () =>
      get('/sdk/protocol', {
        code: 'COMMUNITY',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )
  return (
    <Page title={t('community.title')}>
      <HtmlContainer htmlStr={detailData?.data.content || ''} />
    </Page>
  )
}

export default React.memo(Community)
