/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getDeviceInfo = () => {
  let res = '{}'
  if (window.isiOS) {
    res = prompt('getDeviceInfo')
  }
  if (window.isAndroid) {
    res = android.getDeviceInfo()
  }
  return JSON.parse(res)
}

export const getRoleInfo = () => {
  try {
    let res = '{}'
    if (window.isiOS) {
      res = prompt('getRoleInfo')
    }
    if (window.isAndroid) {
      res = android.getRoleInfo()
    }
    return JSON.parse(res)
  } catch (e) {
    return {}
  }
}

export const closeWebview = () => {
  if (window.isiOS) {
    prompt('closeWebView')
  }
  if (window.isAndroid) {
    android.closeWebView()
  }
}

export const logOffSuccess = (data) => {
  if (window.isiOS) {
    window.webkit.messageHandlers.ocFuncWithParams.postMessage({
      funcName: 'logOffSuccess',
      data,
    })
  }
  if (window.isAndroid) {
    android.logOffSuccess(JSON.stringify(data))
  }
}
