/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const SDKNativeEvents = {}

const chars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

function randomStr() {
  let res = ''
  for (let i = 0; i < 5; i++) {
    const id = Math.ceil(Math.random() * 35)
    res += chars[id]
  }
  return res
}

function getUniqueKey() {
  return `${randomStr()}${new Date().getTime()}`
}

window.js_callback_with_params = (funcName, data) => {
  try {
    if (SDKNativeEvents[funcName]) {
      SDKNativeEvents[funcName](data)
      SDKNativeEvents[funcName] = null
    }
  } catch (e) {
    alert(e)
  }
}

export const callNativeFuncWithParams = (funcName, data, callback) => {
  if (!data || !callback) {
    return
  }
  const callbackName = `funcName${getUniqueKey()}`
  SDKNativeEvents[callbackName] = callback

  if (window.isiOS) {
    window.webkit.messageHandlers.ocFuncWithParams.postMessage({
      funcName,
      data,
      callbackName,
    })
  } else {
    // eslint-disable-next-line no-undef
    android.javaFuncWithParams(funcName, data, callbackName)
  }
}
