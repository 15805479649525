import React from 'react'
import { Helmet } from 'react-helmet'

interface PageProps {
  title?: string
  children: JSX.Element | JSX.Element[]
}

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { title, children } = props
  return (
    <div
      className="page-content-container"
      style={{ overflowY: 'scroll', height: '100vh' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

Page.defaultProps = {
  title: '',
}

export default React.memo(Page)
