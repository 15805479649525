import React, { useCallback, useContext, useState } from 'react'
import Page from '@/components/page'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import { useTranslation } from 'react-i18next'
import BottomBar from '@/components/bottom-bar'
import HTMLContainer from '@/components/html-container'
import { Checkbox, Button } from 'antd-mobile'
import { useHistory } from 'react-router'
import { DeviceInfo } from '@/context/device-info'
import { ProtocolReturnData } from './type'

import './index.scss'
import { goToNext } from './utils'

interface LogOffStatusReturnData {
  code: number
  current_time: number
  data: {
    created_at: string
    apply_logoff: boolean
    logoff_at: string
  }
  message: string
}

const Condition: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [checked, setChecked] = useState(false)
  const deviceInfo = useContext(DeviceInfo)

  const { data: logOffResultData } = useQuery<
    unknown,
    LogOffStatusReturnData | Error,
    LogOffStatusReturnData
  >('logoffStatus', async () => get('/sdk/logoff'), {
    refetchOnWindowFocus: false,
    retry: 0,
  })
  if (logOffResultData?.data.apply_logoff) {
    history.replace('/log-off/result', logOffResultData.data)
  }

  const { data } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'protocol',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_CONDITION',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  const onAgreeChange = useCallback((e) => {
    setChecked(e.target.checked)
  }, [])

  const onClickNext = useCallback(() => {
    goToNext(history, 'first', deviceInfo.gameCode, deviceInfo.env)
  }, [history, deviceInfo.gameCode, deviceInfo.env])

  return (
    <Page title={t('logOff.condition.title')}>
      <HTMLContainer bottomSpan={108} htmlStr={data?.data.content || ''} />
      <BottomBar>
        <Checkbox.AgreeItem defaultChecked={false} onChange={onAgreeChange}>
          {t('logOff.condition.conditionAchieved')}
        </Checkbox.AgreeItem>
        <Button
          type="primary"
          className="next-btn"
          disabled={!checked}
          onClick={onClickNext}
        >
          {t('logOff.nextStep')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(Condition)
