import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '@/components/page'
import { closeWebview, getRoleInfo } from '@/bridges'
import { Text, Button } from 'antd-mobile'
import { useHistory } from 'react-router'
import BottomBar from '@/components/bottom-bar'
import { DeviceInfo } from '@/context/device-info'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import { Role, RoleInfo } from './type'
import { getCurrentPageConfig, goToNext } from './utils'

import './index.scss'

interface LogOffStatusReturnData {
  code: number
  current_time: number
  data: {
    created_at: string
    apply_logoff: boolean
    logoff_at: string
  }
  message: string
}

const ConfirmAccount: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const deviceInfo = useContext(DeviceInfo)

  const currentPageConfig = getCurrentPageConfig(
    'confirmAccount',
    deviceInfo.gameCode,
    deviceInfo.env
  )

  const roleInfo: RoleInfo = currentPageConfig.showRoleInfo ? getRoleInfo() : {}

  const { data: logOffResultData } = useQuery<
    unknown,
    LogOffStatusReturnData | Error,
    LogOffStatusReturnData
  >('logoffStatus', async () => get('/sdk/logoff'), {
    refetchOnWindowFocus: false,
    retry: 0,
  })
  if (logOffResultData?.data.apply_logoff) {
    history.replace('/log-off/result', logOffResultData.data)
  }

  const onClickNext = useCallback(() => {
    goToNext(history, 'confirmAccount', deviceInfo.gameCode, deviceInfo.env)
  }, [history, deviceInfo.gameCode, deviceInfo.env])

  const onClickCancel = useCallback(() => {
    closeWebview()
  }, [])

  return (
    <Page title={t('logOff.chooseAccount.title')}>
      <Text className="title">{t('logOff.chooseAccount.confirmTitle')}</Text>
      {currentPageConfig.showRoleInfo ? (
        <>
          <Text className="content">
            {t('logOff.chooseAccount.count', {
              count: roleInfo.roleList.length,
            })}
          </Text>
          <div className="role-list">
            <div className="header">
              <div>{t('logOff.chooseAccount.id')}</div>
              <div>{t('logOff.chooseAccount.job')}</div>
              <div>{t('logOff.chooseAccount.level')}</div>
              <div>{t('logOff.chooseAccount.server')}</div>
            </div>
            {roleInfo.roleList.map((role: Role) => (
              <div key={role.roleId} className="role">
                <div>{role.roleId}</div>
                <div>{role.class}</div>
                <div>{role.level}</div>
                <div>{role.serverId}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="account-deletion-tip">
        {t('logOff.chooseAccount.deletionTip')
          .split('\n')
          .map((str) => (
            <div style={{ marginTop: 10 }} key={str}>
              {str}
              <br />
            </div>
          ))}
      </div>
      <div style={{ height: 124 }}></div>
      <BottomBar>
        <Button type="primary" className="next-btn" onClick={onClickCancel}>
          {t('logOff.confirmation.cancel')}
        </Button>
        <Button type="primary" className="next-btn" onClick={onClickNext}>
          {t('logOff.nextStep')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(ConfirmAccount)
