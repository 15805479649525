export const LOGOFF_PROCEDURE: {
  [key: string]: any
} = {
  ro: {
    kr: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmation',
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
    jp: {
      confirmAccount: {
        currentRoute: '/log-off/confirm-account',
        showRoleInfo: false,
        nextKey: 'deleteOtherDevice',
      },
      deleteOtherDevice: {
        currentRoute: '/log-off/delete-other-device',
        nextKey: 'confirmation',
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
    na: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmation',
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
    hmt: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmation',
        showDetail: false,
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
    sea: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmation',
        showDetail: false,
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
  },
  jjsg: {
    cn: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmAccount',
      },
      confirmAccount: {
        currentRoute: '/log-off/confirm-account',
        showRoleInfo: true,
        nextKey: 'identification',
      },
      identification: {
        currentRoute: '/log-off/identification',
        nextKey: 'confirmation',
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
    jp: {
      first: {
        currentRoute: '/log-off/condition',
        nextKey: 'briefAgreement',
      },
      briefAgreement: {
        currentRoute: '/log-off/brief-agreement',
        nextKey: 'confirmation',
      },
      confirmation: {
        currentRoute: '/log-off/confirmation',
        nextKey: 'result',
      },
      result: {
        currentRoute: '/log-off/result',
        nextKey: '',
      },
    },
  },
}
