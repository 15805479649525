import React, { useCallback } from 'react'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import { useTranslation } from 'react-i18next'
import HTMLContainer from '@/components/html-container'
import BottomBar from '@/components/bottom-bar'
import Page from '@/components/page'
import { closeWebview } from '@/bridges'
import { Button } from 'antd-mobile'
import { useLocation } from 'react-router'
import { ProtocolReturnData } from './type'

interface LogOffResult {
  apply_logoff: boolean
  created_at: string
  logoff_at: string
}

const Result: React.FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation()

  const { data } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'result',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_RESULT',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  const onClickComplete = useCallback(() => {
    closeWebview()
  }, [])

  return (
    <Page title={t('logOff.result.title')}>
      <HTMLContainer
        htmlStr={
          data?.data.content.replace(
            '{{time}}',
            (state as LogOffResult).logoff_at
          ) || ''
        }
      />
      <BottomBar>
        <Button type="primary" className="next-btn" onClick={onClickComplete}>
          {t('logOff.result.complete')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(Result)
