import React, { useCallback, useState, useContext } from 'react'
import { useMutation } from 'react-query'
import { get } from '@/utils/fetch'
import { useTranslation } from 'react-i18next'
import Page from '@/components/page'
import BottomBar from '@/components/bottom-bar'
import { List, Text, InputItem, Button, Toast } from 'antd-mobile'
import { useHistory } from 'react-router'

import { DeviceInfo } from '@/context/device-info'
import './index.scss'
import { goToNext } from './utils'

interface IdentificationForm {
  name: string
  idNumber: string
}

const Identification: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [idNumber, setIdNumber] = useState('')
  const [name, setName] = useState('')
  const deviceInfo = useContext(DeviceInfo)

  const submitIdentification = useMutation(
    async (identification: IdentificationForm) =>
      get('/sdk/idcard/check4logoff', {
        name: identification.name,
        id_num: identification.idNumber,
      }),
    {
      onSuccess: async (data) => {
        if (data.code === 200) {
          Toast.success(
            t('logOff.identification.success'),
            1,
            () => {
              goToNext(
                history,
                'identification',
                deviceInfo.gameCode,
                deviceInfo.env,
                {
                  idNumber,
                  name,
                }
              )
            },
            true
          )
        }
      },
    }
  )

  const onInputIdNumber = useCallback((e) => {
    setIdNumber(e)
  }, [])
  const onInputName = useCallback((e) => {
    setName(e)
  }, [])

  const onClickIdentify = useCallback(async () => {
    if (idNumber.length !== 18) {
      Toast.fail(t('logOff.identification.errorIdNumber'))
      return
    }
    if (!name) {
      Toast.fail(t('logOff.identification.errorName'))
      return
    }
    await submitIdentification.mutate({
      idNumber,
      name,
    })
  }, [idNumber, name, submitIdentification, t])

  return (
    <Page title={t('logOff.identification.title')}>
      <Text className="title">{t('logOff.identification.viewTitle')}</Text>
      <Text className="content">{t('logOff.identification.tip')}</Text>
      <List>
        <InputItem
          placeholder={t('logOff.identification.inputIDPlaceholder')}
          id="idNumber"
          required
          minLength={18}
          maxLength={18}
          onChange={onInputIdNumber}
        >
          {t('logOff.identification.idNumber')}
        </InputItem>
        <InputItem
          placeholder={t('logOff.identification.inputNamePlaceholder')}
          id="name"
          required
          onChange={onInputName}
        >
          {t('logOff.identification.name')}
        </InputItem>
      </List>
      <BottomBar>
        <Button
          disabled={submitIdentification.isLoading}
          type="primary"
          className="next-btn"
          onClick={onClickIdentify}
        >
          {t('logOff.identification.identify')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(Identification)
