// judge iOS or Android
const { userAgent } = navigator
window.isAndroid =
  userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1

window.isiOS =
  userAgent.indexOf('Android') < 0 &&
  (userAgent.indexOf('iPhone') > -1 ||
    userAgent.indexOf('Safari') > -1 ||
    userAgent.indexOf('iPad') > -1 ||
    userAgent.indexOf('Macintosh') > -1 ||
    userAgent.indexOf('Mac OS X') > -1)

window.isMobile = window.isAndroid || window.isiOS
