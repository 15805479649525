import React from 'react'
import './index.scss'

interface BottomBarProps {
  children: JSX.Element | JSX.Element[]
}

const BottomBar: React.FC<BottomBarProps> = (props: BottomBarProps) => {
  const { children } = props
  return <div className="bottom-bar-container">{children}</div>
}

export default React.memo(BottomBar)
