import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '@/components/page'
import { closeWebview } from '@/bridges'
import { Text, Button } from 'antd-mobile'
import { useHistory } from 'react-router'
import BottomBar from '@/components/bottom-bar'
import { DeviceInfo } from '@/context/device-info'
import { useQuery } from 'react-query'
import { get } from '@/utils/fetch'
import HTMLContainer from '@/components/html-container'
import { ProtocolReturnData } from './type'
import { getCurrentPageConfig, goToNext } from './utils'

import './index.scss'

const DeleteOtherDevice: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const deviceInfo = useContext(DeviceInfo)

  const { data } = useQuery<
    unknown,
    ProtocolReturnData | Error,
    ProtocolReturnData
  >(
    'confirmation',
    async () =>
      get('/sdk/protocol', {
        code: 'LOGOFF_DEL_OTHER_DEVICE',
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )

  const onClickNext = useCallback(() => {
    goToNext(history, 'deleteOtherDevice', deviceInfo.gameCode, deviceInfo.env)
  }, [history, deviceInfo.gameCode, deviceInfo.env])

  const onClickCancel = useCallback(() => {
    closeWebview()
  }, [])

  return (
    <Page title={t('logOff.deleteOtherDevice.title')}>
      <Text className="title">{t('logOff.deleteOtherDevice.title')}</Text>
      <HTMLContainer bottomSpan={166} htmlStr={data?.data.content || ''} />
      <BottomBar>
        <Button type="primary" className="next-btn" onClick={onClickCancel}>
          {t('logOff.confirmation.cancel')}
        </Button>
        <Button type="primary" className="next-btn" onClick={onClickNext}>
          {t('logOff.nextStep')}
        </Button>
      </BottomBar>
    </Page>
  )
}

export default React.memo(DeleteOtherDevice)
