export const isPC = (): boolean => {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

export const getParamsFromSearchStr = (): JsonObject => {
  const searchStr = window.location.search
  const res: JsonObject = {}
  if (!searchStr) {
    return res
  }
  const paramsStr = searchStr.replace('?', '')
  const keyValueStrArray = paramsStr.split('&')
  keyValueStrArray.forEach((item) => {
    const [key, value] = item.split('=')
    res[key] = value
  })
  return res
}
