import React from 'react'
import { Redirect } from 'react-router-dom'
import LogOff from '@/pages/log-off'
import { RouteConfig } from 'react-router-config'
import Condition from '@/pages/log-off/condition'
import BriefAgreement from '@/pages/log-off/brief-agreement'
import Result from '@/pages/log-off/result'
import ConfirmAccount from '@/pages/log-off/confirm-account'
import Identification from '@/pages/log-off/identification'
import DeleteOtherDevice from '@/pages/log-off/delete-other-device'
import Confirmation from '@/pages/log-off/confirmation'
import { LOGOFF_PROCEDURE } from '@/pages/log-off/config'
import Community from '@/pages/community'
import CommunityLayout from '@/pages/community/layout'

const routes: RouteConfig[] = [
  {
    path: '/log-off',
    component: LogOff,
    routes: [
      {
        path: '/log-off',
        exact: true,
        render: (): JSX.Element => {
          const deviceInfo = JSON.parse(
            localStorage.getItem('JOY_SDK_DEVICE_INFO') || '{}'
          )
          console.log(deviceInfo)
          const { gameCode, env } = deviceInfo
          console.log('deviceInfo', deviceInfo)
          const region =
            env.split('-')[0] === 'ready'
              ? process.env.REACT_APP_LOG_OFF_READY_REGION || ''
              : env.split('-')[0]
          console.log('gameCode：', gameCode)
          console.log('env：', env)
          console.log('region：', region)
          if (!gameCode || !env) {
            return <Redirect to="/log-off/condition" />
          }
          const config = LOGOFF_PROCEDURE[gameCode][region]
          const firstKey = Object.keys(config)[0]
          return <Redirect to={config[firstKey].currentRoute} />
        },
      },
      {
        path: '/log-off/condition',
        component: Condition,
      },
      {
        path: '/log-off/brief-agreement',
        component: BriefAgreement,
      },
      {
        path: '/log-off/confirm-account',
        component: ConfirmAccount,
      },
      {
        path: '/log-off/delete-other-device',
        component: DeleteOtherDevice,
      },
      {
        path: '/log-off/identification',
        component: Identification,
      },
      {
        path: '/log-off/confirmation',
        component: Confirmation,
      },
      {
        path: '/log-off/result',
        component: Result,
      },
    ],
  },
  {
    path: '/community',
    component: CommunityLayout,
    routes: [
      {
        path: '/',
        component: Community,
      },
    ],
  },
]

export default routes
