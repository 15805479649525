import { LOGOFF_PROCEDURE } from './config'

export const getCurrentPageConfig = (
  key: string,
  gameCode: string,
  env: string
): JsonObject => {
  const region =
    env.split('-')[0] === 'ready'
      ? process.env.REACT_APP_LOG_OFF_READY_REGION || ''
      : env.split('-')[0]
  return LOGOFF_PROCEDURE[gameCode][region][key]
}

export const goToNext = (
  history: unknown,
  currentPageKey: string,
  gameCode: string,
  env: string,
  otherParams: JsonObject = {}
): void => {
  const region =
    env.split('-')[0] === 'ready'
      ? process.env.REACT_APP_LOG_OFF_READY_REGION || ''
      : env.split('-')[0]
  const { nextKey } = LOGOFF_PROCEDURE[gameCode][region][currentPageKey]
  if (nextKey) {
    const next = LOGOFF_PROCEDURE[gameCode][region][nextKey]
    ;(history as any).push(next.currentRoute, otherParams)
  }
}
